<template>
  <div>
    <div class="underMaterial">
      <div class="header">
        <div class="title">欠料分析</div>
        <div class="right">
          <img src="@/assets/images/dataManage/export.png" v-premiSub="'欠料分析_导出'" @click="handleExport()">
          <el-input v-premiSub="'欠料分析_搜索'" suffix-icon="el-icon-search" size="small" v-model="searchValue" clearable @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
        <el-table-column type="index" label="序号" fixed="left" width="80">
        </el-table-column>
        <el-table-column prop="orderNo" label="订单号">
        </el-table-column>
        <el-table-column prop="materialNo" label="物料编码">
        </el-table-column>
        <el-table-column prop="materialName" label="物料名称">
        </el-table-column>
        <el-table-column prop="partCode" label="图号">
        </el-table-column>
        <el-table-column prop="versionCode" label="版本号">
        </el-table-column>
        <el-table-column prop="materialProperty" label="物料属性">
        </el-table-column>
        <el-table-column prop="requiredQuantity" label="需求数量">
        </el-table-column>
        <el-table-column prop="inventoryQuantity" label="在库数量">
        </el-table-column>
        <el-table-column prop="shortageQuantity" label="欠料数量">
        </el-table-column>
        <el-table-column prop="recommendedPurchaseDate" label="推荐采购日期">
        </el-table-column>
        <el-table-column prop="expectedDeliveryDate" label="预计到货日期">
        </el-table-column>
        <el-table-column prop="materialUseDate" label="用料日期">
        </el-table-column>
        <el-table-column prop="orderCompletionDate" label="订单交付期">
        </el-table-column>
      </el-table>
      <div class="page-box">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaterialShortageData,exportMaterialShortageData } from '@/api/index.js'
import dayjs from 'dayjs'

export default {
  name: "underMaterial",
  data() {
    return {
      tableData: [],
      currentPage1: 1,
      total: 0,  //总条数，用于分组组件
      pageChange: 10,   //当前页面条数
      searchValue:'',
    }
  },

  methods: {
    //获取欠料分析数据
    async getMaterialShortageDataFuc(){
      let params={
        page:this.currentPage1,
        size:this.pageChange,
        searchValue:this.searchValue,
      };
      const { code, msg, data:{ records, total }} = await getMaterialShortageData(params);
      if (code == 0) {
        this.tableData = records;
        this.total = total;
      }else{
        this.$message({
          showClose: true,
          message: msg,
          type: 'error',
          duration: 0
        })
      }
    },
    // 点击搜索按钮
    searchHandler(){
      this.getMaterialShortageDataFuc()
    },
    //切换每页显示多少
    handleSizeChange(val){
      this.pageChange=val
      this.getMaterialShortageDataFuc()
    },
    //切换当前页
    handleCurrentChange(val){
      this.currentPage1=val
      this.getMaterialShortageDataFuc()
    },
    //导出
    async handleExport(){
      exportMaterialShortageData().then(res => {
        const filename = `欠料分析表${dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}.xlsx`
        const url = window.URL.createObjectURL(res)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.documentElement.appendChild(link)
        link.click()
        document.documentElement.removeChild(link)
        this.$message.success('导出成功！')
      })
    }
  },
  created() {
    this.getMaterialShortageDataFuc()
  }
};
</script>

<style lang="scss" scoped>
.underMaterial {
  margin: 20px 25px;
  padding: 20px 25px;
  border-radius: 8px;
  background-color: #232323;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    .title {
      color: #fff;
      font-size: 20px;
    }

    .right {
      img {
        width: 25px;
        margin-right: 10px;
        cursor: pointer;
      }

      display: flex;
      align-items: center;
    }
  }
  .page-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
::v-deep .el-input__suffix-inner{
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-pagination {
  text-align: right;
}
.el-input {
  width: 230px;
  margin-left: 25px;
}
::v-deep .el-table th.el-table__cell {
  background-color: #2c2c2c;
  color: #B9B9B9;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #fff;
  border: 0px solid #454545 !important;
}
$c:#AEAEAE;

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  color: $c;
}
::v-deep .el-pagination.is-background .el-pager li {
  border: 1px solid #454545 !important;
}
::v-deep .el-pagination__jump {
  color: $c;
}

::v-deep .el-input__inner {
  color: $c;
  border: 1px solid #4F4F4F;
}

::v-deep .el-pagination.is-background .btn-prev {
  color: $c;
}

::v-deep .el-pagination.is-background .btn-next {
  color: $c;
}

::v-deep .el-pagination.is-background .el-pager li {
  border-radius: 5px !important;
}

::v-deep .el-icon-search {
  font-size: 17px;
}
::v-deep .el-pagination__editor{
  margin: 0px 8px;
}
$border-bottom: 1px solid #343434;
</style>
